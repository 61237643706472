<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="wizardDossierDetailsStep3Form"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <h2 class="tw-mt-0">Constructie</h2>
    <FormulateInput
      type="select"
      name="glazing"
      label="Type beglazing"
      :options="GLAZING_OPTIONS"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="select"
      name="outside_joinery_material"
      label="Schrijnwerk buiten"
      :options="OUTSIDE_JOINERY_MATERIAL_OPTIONS"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="select"
      name="cavity_wall"
      label="Spouwmuur"
      :options="CAVITY_WALL_OPTIONS"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="select"
      name="roof_type"
      label="Daktype"
      :options="ROOF_TYPE_OPTIONS"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="select"
      name="ventilation_system"
      label="Ventilatiesysteem"
      :options="VENTILATION_SYSTEM_OPTIONS"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="translatable"
      name="blinds_description"
      label="Extra info over rolluiken"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3 tw-w-full']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :outer-class="['tw-flex tw-items-center']"
      :element-class="['tw-col-span-2']"
    />
    <hr class="tw-border-1 tw-border-gray-cc tw-my-4" />
    <h2>Water</h2>
    <FormulateInput
      type="select"
      name="water_meter"
      label="Watermeter"
      :options="WATER_METER_OPTIONS"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <CustomRadioGroup
      v-model="values.water_softener"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="water_softener"
      label="Waterverzachter"
    />
    <CustomRadioGroup
      v-model="values.water_connection"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="water_connection"
      label="Aansluiting waternet"
    />
    <CustomRadioGroup
      v-model="values.rain_pit"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="rain_pit"
      label="Regenwaterput"
    />
    <FormulateInput
        v-if="values.rain_pit == YES"
        type="number"
        name="rain_pit_capacity"
        label="Inhoud regenwaterput"
        min="0"
        step=".01"
        :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
        :outer-class="['!tw-my-2']"
        :input-class="['tw-rounded-tr-none tw-rounded-br-none !tw-my-0 hide-number-input-arrows']"
        :label-class="['tw-block md:tw-flex tw-items-center md:tw-pl-8']"
        :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
      <div slot="suffix" class="input-unit !tw-my-0">
        l
      </div>
    </FormulateInput>
    <CustomRadioGroup
      v-model="values.water_well"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="water_well"
      label="Grondwaterput"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.sewerage"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="sewerage"
      label="Rioolaansluiting"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.septic_tank"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="septic_tank"
      label="Septische put"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.cesspool"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="cesspool"
      label="Beerput"
      class="tw-mb-4"
    />
    <hr class="tw-border-1 tw-border-gray-cc tw-my-4" />
    <h2>Telecom</h2>
    <FormulateInput
      type="select"
      name="telecom_connection"
      label="Aansluiting telecom"
      :options="TELECOM_CONNECTION_OPTIONS"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <CustomRadioGroup
      v-model="values.alarm"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="alarm"
      label="Alarm"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.home_automation"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="home_automation"
      label="Domotica"
      class="tw-mb-4"
    />
    <div class="tw-grid tw-grid-cols-2 md:tw-flex tw-justify-end tw-gap-x-2">
      <FormulateErrors />
      <FormulateInput
        type="button"
        :input-class="['tw-w-full tw-bg-gray-500']"
        :disabled="saving"
        @click="goBack"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
      <FormulateInput
        type="submit"
        title="Volgende stap"
        :input-class="['tw-w-full']"
        :disabled="isLoading"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
          ]"
        />
        Volgende <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import {
  CAVITY_WALL_OPTIONS,
  GLAZING_OPTIONS,
  OUTSIDE_JOINERY_MATERIAL_OPTIONS,
  ROOF_TYPE_OPTIONS,
  TELECOM_CONNECTION_OPTIONS,
  VENTILATION_SYSTEM_OPTIONS,
  WATER_METER_OPTIONS,
  YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS
} from '@/forms/selectOptions'
import CustomRadioGroup from '@/components/base/CustomRadioGroup'
import { updatePropertyComfort, updatePropertyEnergy, updatePropertyTechnical } from '@/services/properties'

export default {
  name: 'WizardDossierDetailsStep3',
  components: {
    CustomRadioGroup
  },
  constants: {
    CAVITY_WALL_OPTIONS,
    GLAZING_OPTIONS,
    OUTSIDE_JOINERY_MATERIAL_OPTIONS,
    ROOF_TYPE_OPTIONS,
    TELECOM_CONNECTION_OPTIONS,
    VENTILATION_SYSTEM_OPTIONS,
    WATER_METER_OPTIONS,
    YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS,
    YES: 1
  },
  props: {
    initialValues: {
      type: Object
    },
    propertyId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      values: {},
      saving: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.values = { ...this.initialValues }
    },
    async goBack () {
      this.saving = true
      const response = await this.submit(this.values, false)
      this.saving = false
      return response
    },
    async submit (data, goForward = true) {
      try {
        let {
          cavity_wall,
          roof_type,
          alarm,
          blinds_description,
          cesspool,
          home_automation,
          outside_joinery_material,
          rain_pit,
          rain_pit_capacity,
          septic_tank,
          sewerage,
          telecom_connection,
          ventilation_system,
          water_connection,
          water_softener,
          water_well,
          glazing,
          water_meter
        } = data

        const technicalData = {
          cavity_wall,
          roof_type
        }

        rain_pit_capacity = parseInt(rain_pit) === this.YES ? rain_pit_capacity : null

        const comfortData = {
          alarm,
          blinds_description,
          cesspool,
          home_automation,
          outside_joinery_material,
          rain_pit,
          rain_pit_capacity,
          septic_tank,
          sewerage,
          telecom_connection,
          ventilation_system,
          water_connection,
          water_softener,
          water_well
        }

        const energyData = {
          glazing,
          water_meter
        }

        // Build promises
        const technicalPromise = updatePropertyTechnical(this.propertyId, technicalData)
        const comfortPromise = updatePropertyComfort(this.propertyId, comfortData)
        const energyPromise = updatePropertyEnergy(this.propertyId, energyData)

        const response = await Promise.all([technicalPromise, comfortPromise, energyPromise])

        if (goForward) this.$emit('finished')
        else this.$emit('goBack')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'wizardDossierDetailsStep3Form')
        throw error
      }
    }
  }
}
</script>
