<template>
  <BaseModal ref="modal" title="Panddetails" max-width="tw-max-w-3xl">
    <WizardDossierDetailsStep1
      ref="wizardDossierDetailsStep1"
      v-show="activeStep === 1"
      :initial-values="{ ...propertyValues, ...technicalValues }"
      :property-id="propertyId"
      @finished="moveForward"
    />
    <WizardDossierDetailsStep2
      v-show="activeStep === 2"
      :initial-values="{ ...energyValues }"
      :property-id="propertyId"
      @goBack="goBack"
      @finished="moveForward"
    />
    <WizardDossierDetailsStep3
      v-show="activeStep === 3"
      :initial-values="{ ...technicalValues, ...comfortValues, ...energyValues }"
      :property-id="propertyId"
      @goBack="goBack"
      @finished="moveForward"
    />
    <WizardDossierDetailsStep4
      v-show="activeStep === 4"
      :initial-values="{ ...commercialValues }"
      :property-id="propertyId"
      @goBack="goBack"
      @finished="moveForward"
    />
    <WizardDossierDetailsStep5
      v-show="activeStep === 5"
      :initial-values="{ ...financialValues, ...legalValues }"
      :property-id="propertyId"
      @goBack="goBack"
      @finished="moveForward"
    />
    <WizardDossierDetailsStep6
      v-show="activeStep === 6"
      :initial-values="{ ...financialValues, ...legalValues, ...technicalValues }"
      :property-id="propertyId"
      @goBack="goBack"
      @finished="moveForward"
    />
    <WizardDossierDetailsStep7
      v-show="activeStep === 7"
      :initial-values="{ ...propertyValues }"
      :property-id="propertyId"
      @goBack="goBack"
      @finished="moveForward"
    />
    <p class="tw-italic tw-text-right">Stap {{ displayStep }} van {{ amountOfSteps }}</p>
  </BaseModal>
</template>

<script>
import EventBus from '@/components/iam/bus'
import { scrollToModalTop } from '@/utils/helpers'

import WizardDossierDetailsStep1 from '@/components/properties/WizardDossierDetailsStep1'
import WizardDossierDetailsStep2 from '@/components/properties/WizardDossierDetailsStep2'
import WizardDossierDetailsStep3 from '@/components/properties/WizardDossierDetailsStep3'
import WizardDossierDetailsStep4 from '@/components/properties/WizardDossierDetailsStep4'
import WizardDossierDetailsStep5 from '@/components/properties/WizardDossierDetailsStep5'
import WizardDossierDetailsStep6 from '@/components/properties/WizardDossierDetailsStep6'
import WizardDossierDetailsStep7 from '@/components/properties/WizardDossierDetailsStep7'

import { getPropertyComfort, getPropertyCommercial, getPropertyEnergy, getPropertyFinancial, getPropertyLegal, getPropertyTechnical } from '@/services/properties'

export default {
  name: 'WizardDossierDetails',
  components: {
    WizardDossierDetailsStep1,
    WizardDossierDetailsStep2,
    WizardDossierDetailsStep3,
    WizardDossierDetailsStep4,
    WizardDossierDetailsStep5,
    WizardDossierDetailsStep6,
    WizardDossierDetailsStep7
  },
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  constants: {
    RESIDENTIAL_NICHE_ID: 1,
    COMMERCIAL_INFORMATION_STEP: 4,
    LAST_STEP: 7
  },
  data () {
    return {
      activeStep: 1,
      propertyValues: null,
      comfortValues: null,
      commercialValues: null,
      energyValues: null,
      legalValues: null,
      financialValues: null,
      technicalValues: null,
      niche: null
    }
  },
  computed: {
    propertyId () {
      return this.property.id
    },
    amountOfSteps () {
      return this.niche === this.RESIDENTIAL_NICHE_ID ? this.LAST_STEP - 1 : this.LAST_STEP
    },
    displayStep () {
      return this.niche === this.RESIDENTIAL_NICHE_ID && this.activeStep > this.COMMERCIAL_INFORMATION_STEP ? this.activeStep - 1 : this.activeStep
    }
  },
  methods: {
    async show () {
      this.activeStep = 1
      const response = await this.loadInitialValues()
      this.$refs.modal.show()
      return response
    },
    hide () {
      this.$refs.modal.hide()
    },
    async loadInitialValues () {
      const [comfort, commercial, energy, financial, legal, technical] = await Promise.all(
        [
          getPropertyComfort(this.propertyId),
          getPropertyCommercial(this.propertyId),
          getPropertyEnergy(this.propertyId),
          getPropertyLegal(this.propertyId),
          getPropertyFinancial(this.propertyId),
          getPropertyTechnical(this.propertyId)
        ]
      )
      this.propertyValues = {
        syndic: this.property.syndic,
        type: this.property.type.id,
        niche: this.property.niche.id,
        positive_factors: this.property.positive_factors,
        negative_factors: this.property.negative_factors
      }
      this.comfortValues = comfort.data
      this.commercialValues = commercial.data
      this.energyValues = energy.data
      this.legalValues = legal.data
      this.financialValues = financial.data
      this.technicalValues = technical.data

      this.niche = this.property.niche.id
      return [comfort, commercial, energy, financial, technical]
    },
    goBack () {
      let newStep = this.activeStep - 1
      if (newStep === this.COMMERCIAL_INFORMATION_STEP && this.niche === this.RESIDENTIAL_NICHE_ID) {
        newStep = 3
      }
      this.activeStep = newStep
      this.$nextTick(() => {
        this.scrollToTop()
      })
    },
    moveForward (data) {
      if (this.activeStep === 1) {
        this.niche = data.niche
      }
      if (this.activeStep === this.LAST_STEP) {
        EventBus.$emit('initProperty')
        this.hide()
      }
      let newStep = this.activeStep + 1
      if (newStep === this.COMMERCIAL_INFORMATION_STEP && this.niche === this.RESIDENTIAL_NICHE_ID) {
        newStep = 5
      }
      this.activeStep = newStep
      this.$nextTick(() => {
        scrollToModalTop(this.$refs.modal?.$el)
      })
    }
  }
}
</script>
