<template>
  <div>
    <Page v-if="property && Object.values(property).length && !loading">
      <ToggleButtonModal
        ref="groupModal"
        type="properties"
        :title="'Groepen'"
        @save="handleToggleModalSave"
      />
      <div slot="actionbar" class="tw-relative">
        <div class="btn-group">
          <a class="btn btn-default" @click="showModal">
            <i class="fa fa-home" /> Groepen
          </a>
          <router-link :to="{ name: 'PropertyPrint', params: { id: property.id } }" class="btn btn-default">
            <i class="fa fa-print" /> Print
          </router-link>
          <div class="btn-group">
            <button
              aria-expanded="false"
              data-toggle="dropdown"
              class="btn btn-default dropdown-toggle"
            >
              Meer <i class="caret" />
            </button>
            <ul class="dropdown-menu pull-right">
              <li v-if="showWizardDossierDetailsButton">
                <a @click="showWizardDossierDetails">
                  <i class="fa fa-list" /> Dossierwizard
                </a>
              </li>
              <li v-if="showTransactionsButton">
                <a @click="showTransactionModal">
                  <i class="fa fa-euro" /> Transacties
                </a>
              </li>
              <li v-if="showBidsButton">
                <a @click="showPropertyBidsModal">
                  <i class="fa fa-gavel" /> Biedingen
                </a>
              </li>
              <li v-if="showDuplicateButton">
                <a @click="showDuplicateModal">
                  <i class="fa fa-copy" /> Dupliceren
                </a>
              </li>
              <li>
                <a @click="showEstimatesModal">
                  <i class="fa fa-calculator" /> Schattingen
                </a>
              </li>
              <li
                v-if="showAgreementButton || showFinishedByOtherBrokerButton || showBackInPortfolioButton || showRemovedFromPortfolioButton"
                class="divider"
                role="separator"
              />
              <li v-if="showAgreementButton">
                <a @click="showAgreementModal">
                  <i class="fas fa-handshake-alt tw-mr-1" />{{ agreementLabel }}
                </a>
              </li>
              <li v-if="showFinishedByOtherBrokerButton">
                <a @click="handleFinishedByOtherBroker">
                  <i class="fa fa-arrow-right" /> {{ finishedByOtherBrokerLabel }}
                </a>
              </li>
              <li v-if="showRemovedFromPortfolioButton">
                <a @click="handleRemovedFromPortfolio">
                  <i class="fa fa-arrow-right" /> Uit portefeuille
                </a>
              </li>
              <li v-if="showBackInPortfolioButton">
                <a @click="handleBackInPortfolio">
                  <i class="fa fa-arrow-left" /> Terug in portefeuille
                </a>
              </li>
              <li v-if="showTransactionsButton" class="divider" role="separator" />
              <li>
                <a v-if="property.published" :href="portalUrl" target="_blank">
                  <i class="fa fa-globe" /> Portal landingspagina
                </a>
              </li>
              <li>
                <router-link :to="{ name: 'PropertyHistory', params: { id: property.id } }">
                  <i class="fa fa-history" /> Historiek
                </router-link>
              </li>
              <li>
                <a @click="deleteProperty">
                  <i class="fa fa-warning" /> Pand verwijderen
                </a>
              </li>
            </ul>
          </div>
        </div>

        <template v-if="surveysAverage">
          <button
            v-if="!showSurveyDropdown"
            type="button"
            class="link tw-font-semibold tw-m-2 tw-pr-2 tw-flex tw-gap-1 tw-justify-end tw-items-center tw-w-full"
            @click="showSurveyDropdown = true"
          >
            <img
              alt="score"
              :src="scores[Math.floor(surveysAverage)].icon"
              class="tw-h-4"
            />
            <span>
              {{ surveysAverage }} ({{ surveys.count }} {{ surveys.count === 1 ? 'CSAT-bevraging' : 'CSAT-bevragingen' }})
            </span>
          </button>
          <button
            v-else
            type="button"
            class="link tw-font-semibold tw-m-2 tw-pr-2 tw-flex tw-gap-1 tw-justify-end tw-items-baseline tw-w-full"
            @click="showSurveyDropdown = false"
          >
            <i class="fas fa-times" />
            Sluiten
          </button>

          <transition name="fade" mode="out-in">
            <div
              v-if="showSurveyDropdown"
              class="tw-py-4 tw-px-6 tw-my-2 tw-absolute tw-z-30 tw-right-0 tw-w-[80vw] md:tw-w-[40vw] tw-max-w-xl tw-shadow-card tw-rounded-md tw-bg-white"
            >
              <div class="tw-pb-4 tw-flex tw-gap-4 tw-items-center">
                <img
                  alt="score"
                  :src="scores[Math.floor(surveysAverage)].icon"
                  class="tw-h-10"
                />
                <h2 class="tw-my-0">
                  Gemiddelde score: {{ surveysAverage }}
                  ({{ surveys.count }} {{ surveys.count === 1 ? 'CSAT-bevraging' : 'CSAT-bevragingen' }})
                </h2>
              </div>
              <div
                v-for="survey in surveys.results"
                :key="survey.id"
                class="tw-pt-4 tw-px-0.5 tw-border-t"
              >
                <div class="tw-flex tw-gap-5">
                  <img
                    alt="score"
                    :src="scores[survey.score].icon"
                    class="tw-h-9"
                  />
                  <div>
                    <div class="tw-mb-4">
                      <h3 class="tw-my-0">
                        {{ survey.type.name }}
                      </h3>
                      <span class="tw-italic">
                        Score: {{ survey.score }} ({{ scores[survey.score].label }})
                      </span>
                    </div>
                    <h4 class="tw-m-0">
                      {{ survey.contact.display_name }}
                    </h4>
                    <p class="tw-italic">
                      {{ survey.comment }}
                    </p>

                    <SurveysAddEditComment
                      :survey="survey"
                      @submitted="fetchSurveysForProperty(propertyId)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </template>
      </div>

      <span slot="title">
        {{ property.reference }}
        <small v-if="property.city">
          {{ property.address }}
        </small>
        <small class="tw-lowercase">
          - {{ property.type.name_nl }} - {{ property.status_display_nl }} {{ [15, 16].includes(property.status) && property.transaction_type_display ? `(${property.transaction_type_display})` : '' }}
        </small>
        <template v-if="propertyIsProspect">
          <small> - </small>
          <FormulateInput
            type="select"
            name="transaction_type"
            placeholder="Transactietype"
            v-model="transaction_type"
            :options="PROPERTY_TRANSACTION_TYPES"
            :input-class="['tw-text-sm tw-h-6 tw-mt-0.5']"
            outer-class="tw-inline-block tw-w-1/8"
            @change="saveTransactionType"
          />
        </template>
        <template v-if="transaction_type_saving">
          <i class="fas fa-spinner-third fa-spin tw-ml-2 tw-text-sm" />
        </template>
      </span>

      <div class="tabs-container">
        <button
          type="button"
          class="btn tw-mx-1.5 tw-mb-3.5 tw-inline-block md:tw-hidden"
          @click="collapseTabs = !collapseTabs"
        >
          <i :class="['far', collapseTabs ? 'fa-bars' : 'fa-times']" />
        </button>
        <transition name="fade">
          <!-- We don't want to collapse tabs on non-mobile devices -->
          <ul
            role="tablist"
            :class="[
              'nav nav-tabs',
              { 'tw-hidden md:tw-block': collapseTabs }
            ]"
          >
            <template v-for="(link, index) in propertyNavLinks">
              <router-link
                v-if="link.to"
                :key="index"
                tag="li"
                :to="link.to"
                data-toggle="tab"
                aria-expanded="false"
                active-class="active"
              >
                <a class="!tw-px-4">{{ link.name }}</a>
              </router-link>
              <li v-else :key="index">
                <a :href="link.href" class="!tw-px-4">{{ link.name }}</a>
              </li>
            </template>
          </ul>
        </transition>
        <div class="tab-content">
          <div class="tab-pane active">
            <div class="panel-body">
              <transition name="fade" mode="out-in">
                <router-view
                  @complete-transaction="completeTransaction"
                  @show-bid-registration="showPropertyBidsModal"
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
      <WizardDossierDetails
        ref="wizardDossierDetails"
        :property="property"
      />
      <TransactionModal ref="transactionModal" :property="property" />
      <PropertyDuplication ref="duplicationModal" :property="property" />
      <PropertyFinishTransaction ref="finishTransaction" v-bind="{ propertyId, transactions }" />
      <WizardSalesAgreement
          ref="wizardSalesAgreement"
          :property="property"
          @submitted="handleAgreementWizardSubmitted"
      />
      <WizardRentalAgreement
        ref="wizardRentalAgreement"
        :property="property"
        @submitted="handleAgreementWizardSubmitted"
      />
      <PropertyBidsModal
        ref="propertyBids"
        :property-concept="property.concept_type"
        :property-id="propertyId"
        @bidAccepted="init"
      />
      <WizardRemovedFromPortfolio
        ref="wizardRemovedFromPortfolio"
        :property="property"
        @submitted="handleRemovedFromPortfolioSubmitted"
        @wizardClosed="init"
      />
      <DossierFinishedByOtherBrokerModal
        ref="dossierFinishedByOtherBrokerModal"
        :property="property"
        :title="finishedByOtherBrokerLabel"
        @openAgreementWizard="showAgreementModal"
        @finishedByOtherBrokerCompleted="init"
      />
      <PropertyEstimatesModal
        ref="estimatesModal"
        :property="property"
      />
    </Page>
    <div v-else key="loading" class="tw-mt-8 tw-h-10 tw-text-center">
      <i
        class="fal fa-spinner-third fa-spin tw-text-3xl"
      />
    </div>
  </div>
</template>

<script>
import scores from '@/utils/surveyScores'
import EventBus from '@/components/iam/bus'
import { mapActions, mapGetters } from 'vuex'
import { errorModal, startLoadingModal, successModal, questionModal, warningModal } from '@/modalMessages'
import { PROPERTY_TRANSACTION_TYPES } from '@/forms/selectOptions'

import Page from '@/components/iam/Page'
import ToggleButtonModal from '@/components/iam/ToggleButtonModal'
import PropertyDuplication from '@/components/properties/PropertyDuplication'
import TransactionModal from '@/components/properties/TransactionModal'
import PropertyBidsModal from '@/components/properties/PropertyBidsModal'
import SurveysAddEditComment from '@/components/SurveysAddEditComment'
import WizardDossierDetails from '@/components/properties/WizardDossierDetails'
import WizardSalesAgreement from '@/components/properties/WizardSalesAgreement'
import WizardRentalAgreement from '@/components/properties/WizardRentalAgreement'
import PropertyEstimatesModal from '@/components/properties/PropertyEstimatesModal'
import PropertyFinishTransaction from '@/components/properties/PropertyFinishTransaction'
import WizardRemovedFromPortfolio from '@/components/properties/WizardRemovedFromPortfolio'
import DossierFinishedByOtherBrokerModal from '@/components/properties/DossierFinishedByOtherBrokerModal'

import { getSurveysAverage, getSurveys } from '@/services/surveys'
import { getPropertiesGroup, updateProperty, updatePropertyGroups, setPropertyDeleted } from '@/services/properties'
import { clearOutgoingTransactionData, clearTransactionPoules } from '@/services/transactions'

export default {
  name: 'PropertyLayout',
  components: {
    Page,
    TransactionModal,
    PropertyDuplication,
    ToggleButtonModal,
    PropertyEstimatesModal,
    PropertyFinishTransaction,
    SurveysAddEditComment,
    PropertyBidsModal,
    WizardDossierDetails,
    WizardSalesAgreement,
    WizardRentalAgreement,
    WizardRemovedFromPortfolio,
    DossierFinishedByOtherBrokerModal
  },
  constants: {
    scores,
    PROPERTY_TRANSACTION_TYPES,
    TRANSACTION_TYPE_SALE: 2,
    TRANSACTION_TYPE_RENTAL: 3,
    TRANSACTION_TYPE_ACQUISITION: 4,
    TRANSACTION_TYPE_RENTAL_MANAGEMENT: 5,
    TRANSACTION_TYPE_SYNDIC: 6,
    STATUS_PROSPECT: 1,
    STATUS_FOR_SALE: 2,
    STATUS_FOR_RENT: 3,
    STATUS_ACQUISITION: 4,
    STATUS_DOSSIER_PREPARATION: 16,
    STATUS_SOLD_OTHER_BROKER: 13,
    STATUS_RENTED_OTHER_BROKER: 14,
    STATUS_REMOVED_FROM_PORTFOLIO: 15,
    STATUS_ACQUIRED_OTHER_BROKER: 17,
    TRANSACTION_END_STATUS_HANDLED_BY_OTHER_BROKER: 'N'
  },
  data () {
    return {
      loading: false,
      groups: [],
      statusToCompleteTransaction: null,
      transactions: [],
      transaction_type: null,
      transaction_type_saving: false,
      surveysAverage: null,
      surveys: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      showSurveyDropdown: false,
      collapseTabs: true
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById', 'getProjectById']),

    propertyId () {
      return this.$route.params.id
    },
    production () {
      return process.env.VUE_APP_ENVIRONMENT === 'prod'
    },

    propertyNavLinks () {
      return [
        {
          to: { name: 'PropertyDetails' },
          name: 'Algemeen'
        },
        {
          to: { name: 'PropertyBuilding' },
          name: 'Pand'
        },
        {
          to: { name: 'PropertyFinancial' },
          name: 'Financieel'
        },
        ...this.production ? [] : [{
          to: { name: 'PropertyRooms' },
          name: 'Indeling (oud)'
        }
        ],
        {
          to: { name: 'NewPropertyRooms' },
          name: 'Indeling'
        },
        {
          to: { name: 'PropertyMedia' },
          name: 'Media'
        },
        {
          to: { name: 'PropertyFiles' },
          name: 'Bestanden'
        },
        {
          to: { name: 'PropertyMarketing' },
          name: 'Marketing'
        },
        {
          to: { name: 'PropertyTexts' },
          name: 'Teksten'
        },
        {
          to: { name: 'PropertyCommunication' },
          name: 'Communicatie'
        },
        {
          to: { name: 'PropertyLeads' },
          name: 'Leads'
        },
        {
          to: { name: 'PropertyChecklist' },
          name: 'Checklist'
        },
        {
          name: 'Bezoekmomenten',
          to: { name: 'PropertyVisitSlots' }
        },
        {
          to: { name: 'PropertyInvoice' },
          name: 'Kosten/facturatie'
        }
      ]
    },
    property () {
      return this.getPropertyById(this.propertyId)
    },
    project () {
      return this.getProjectById(this.property.project)
    },
    portalUrl () {
      if (this.property) {
        return '/portal/property?id=' + this.property.id
      }
      return ''
    },
    propertyIsProspect () {
      return this.property.status === this.STATUS_PROSPECT
    },
    propertyIsRentalManagement () {
      return this.property.transaction_type === this.TRANSACTION_TYPE_RENTAL_MANAGEMENT
    },
    propertyIsSyndic () {
      return this.property.transaction_type === this.TRANSACTION_TYPE_SYNDIC
    },
    agreementLabel () {
      return this.property.transaction_type === this.TRANSACTION_TYPE_RENTAL ? 'Huurovereenkomst' : 'Compromis'
    },
    showDuplicateButton () {
      return !this.propertyIsProspect && !this.propertyIsRentalManagement && !this.propertyIsSyndic
    },
    showBidsButton () {
      if (this.propertyIsProspect) return false
      if (![this.TRANSACTION_TYPE_SALE, this.TRANSACTION_TYPE_ACQUISITION].includes(this.property.transaction_type)) return false
      return true
    },
    showTransactionsButton () {
      return this.property.status !== this.STATUS_PROSPECT
    },
    showWizardDossierDetailsButton () {
      return this.property.status !== this.STATUS_PROSPECT
    },
    showAgreementButton () {
      // isPropertyRentalInDossierPreparation: https://dewaele.atlassian.net/browse/DEW-11124
      const isPropertyRentalInDossierPreparation = this.property.transaction_type === this.TRANSACTION_TYPE_RENTAL && this.property.status === this.STATUS_DOSSIER_PREPARATION
      const isPropertyInAgreementPhase = ['sales_agreement', 'rental_agreement'].includes(this.property?.stage?.key)
      return isPropertyRentalInDossierPreparation || isPropertyInAgreementPhase
    },
    showFinishedByOtherBrokerButton () {
      return [this.STATUS_DOSSIER_PREPARATION, this.STATUS_FOR_SALE, this.STATUS_FOR_RENT, this.STATUS_ACQUISITION].includes(this.property.status)
    },
    showRemovedFromPortfolioButton () {
      return [this.STATUS_FOR_SALE, this.STATUS_FOR_RENT, this.STATUS_ACQUISITION, this.STATUS_DOSSIER_PREPARATION].includes(this.property.status)
    },
    showBackInPortfolioButton () {
      return [this.STATUS_REMOVED_FROM_PORTFOLIO, this.STATUS_SOLD_OTHER_BROKER, this.STATUS_RENTED_OTHER_BROKER, this.STATUS_ACQUIRED_OTHER_BROKER].includes(this.property.status)
    },
    finishedByOtherBrokerLabel () {
      const statusFinishedByOtherBrokerLabelMap = {
        // Cannot use consts as keys :(
        2: 'Verkocht door andere makelaar', // STATUS_FOR_SALE
        3: 'Verhuurd door andere makelaar', // STATUS_FOR_RENT
        4: 'Overname gerealiseerd door andere makelaar' // STATUS_ACQUISITION
      }
      return statusFinishedByOtherBrokerLabelMap[this.property.transaction_type]
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('properties', [
      'loadProperty',
      'loadProject'
    ]),

    completeTransaction (status, transactions) {
      this.statusToCompleteTransaction = status
      this.transactions = transactions
      status === this.STATUS_REMOVED_FROM_PORTFOLIO ? this.$refs.finishTransaction.show() : this.showTransactionModal()
    },
    showWizardDossierDetails () {
      this.$refs.wizardDossierDetails.show()
    },
    showTransactionModal () {
      this.$refs.transactionModal.show()
    },
    showDuplicateModal () {
      this.$refs.duplicationModal.show()
    },
    showAgreementModal (prefillOptions = {}) {
      if ([this.TRANSACTION_TYPE_SALE, this.TRANSACTION_TYPE_ACQUISITION].includes(this.property.transaction_type)) this.$refs.wizardSalesAgreement.prefillAndShow(prefillOptions)
      if (this.property.transaction_type === this.TRANSACTION_TYPE_RENTAL) this.$refs.wizardRentalAgreement.prefillAndShow(prefillOptions)
    },
    showPropertyBidsModal () {
      this.$refs.propertyBids.show()
    },
    showEstimatesModal () {
      this.$refs.estimatesModal.show()
    },

    async showModal () {
      try {
        const payload = {
          params: {
            property_id: this.propertyId
          }
        }
        const response = await await getPropertiesGroup(payload)
        const selectedGroups = response.data?.results.map(group => group.id)
        this.$refs.groupModal.show(selectedGroups)
      } catch (error) {
        errorModal('Fout bij het laden van groepen voor het pand, probeer het opnieuw.')
      }
    },

    async init () {
      try {
        this.loading = true
        const response = await Promise.all([
          this.loadProperty(this.propertyId),
          this.fetchSurveysAverage(this.propertyId),
          this.fetchSurveysForProperty(this.propertyId)
        ])
        if (this.property?.project) await this.loadProject(this.property.project)
        EventBus.$on('initProperty', this.init)
        this.transaction_type = this.property.transaction_type
        return response
      } catch (error) {
        errorModal('Fout bij het ophalen van pandgegevens, probeer het opnieuw.')
      } finally {
        this.loading = false
      }
    },
    async fetchSurveysAverage (property) {
      // property here refers to the property id
      const response = await getSurveysAverage({ property })
      this.surveysAverage = response.data.average
      return response
    },
    async fetchSurveysForProperty (property) {
      // property here refers to the property id
      const response = await getSurveys({ params: { property } })
      this.surveys = response.data
      return response
    },

    async handleToggleModalSave (group_id_list) {
      try {
        startLoadingModal('Wijzigingen worden doorgevoerd.')
        await updatePropertyGroups(this.property.id, { group_id_list })
        successModal('De groepen waar dit pand in zit werden succesvol gewijzigd')
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het opslaan van pandgroepen, probeer het opnieuw.')
      }
    },
    async deleteProperty () {
      try {
        const questionModalString = `Het verwijderen van dit pand kan niet ongedaan gemaakt worden.<br><br>
                                    <strong>Panden met leads, biedingen, bestellingen, digitale ondertekeningen en HMODH\'s
                                     kunnen niet worden verwijderd en moeten worden gearchiveerd.</strong>`
        const result = await questionModal(questionModalString)
        if (!result.value) return
        const response = await setPropertyDeleted(this.property.id)
        this.$router.push({ name: 'PropertySearch' })
        return response
      } catch (error) {
        errorModal(`Het pand kan niet worden verwijderd en moet in de plaats daarvan worden gearchiveerd.<br><br><strong>${error.response?.data?.non_field_errors?.[0]}</strong>`)
      }
    },

    async saveTransactionType () {
      this.transaction_type_saving = true
      const response = await updateProperty(this.property.id, { transaction_type: this.transaction_type })
      await this.loadProperty(this.propertyId)
      this.transaction_type_saving = false
      successModal('Transactietype gewijzigd')
      return response
    },

    async handleFinishedByOtherBroker () {
      this.$refs.dossierFinishedByOtherBrokerModal.show()
    },

    handleRemovedFromPortfolio () {
      this.$refs.wizardRemovedFromPortfolio.show()
    },

    canBeBroughtBackInPortfolio (transaction) {
      if (!transaction || !transaction.transaction_end_date) return true
      // Properties with a transaction where the end date is > x months ago, cannot be brought back in portfolio.
      // X as defined in December 2024 is 3
      const inputDate = new Date(transaction.transaction_end_date)
      const currentDate = new Date()
      const threeMonthsAgo = new Date()
      threeMonthsAgo.setMonth(currentDate.getMonth() - 3)
      return inputDate > threeMonthsAgo
    },
    async handleBackInPortfolio () {
      if (!this.canBeBroughtBackInPortfolio(this.property.transaction)) {
        return warningModal('Dit pand werd meer dan 3 maanden geleden uit portefeuille genomen en kan niet opnieuw worden geactiveerd. Gelieve dit pand te dupliceren wanneer het terug actief moet komen.')
      }
      const questionModalString = 'Wens je dit dossier terug in portefeuille op te nemen? Let op: controleer zeer goed jouw dossieropbouw. Zijn alle wettelijke documenten nog geldig?'
      const result = await questionModal(questionModalString, 'Opnemen in portefeuille')
      if (!result.value) return
      const stage_key = this.property.transaction_type === this.TRANSACTION_TYPE_RENTAL ? 'rental_agreement' : 'bid_registration'
      const propertyResponse = await updateProperty(this.propertyId, { status: this.property.transaction_type, stage_key })
      let transactionResponse = null
      let poulesResponse = null
      if (this.property.transaction) {
        transactionResponse = await clearOutgoingTransactionData(this.property.transaction.id)
        poulesResponse = await clearTransactionPoules(this.property.transaction.id)
      }
      await this.init()
      return [propertyResponse, transactionResponse, poulesResponse]
    },

    async handleAgreementWizardSubmitted () {
      return await this.loadProperty(this.propertyId) // No .init() call to prevent a page refresh.
    },

    async handleRemovedFromPortfolioSubmitted () {
      successModal('Dossier werd uit portefeuille gehaald')
      return await this.loadProperty(this.propertyId) // No .init() call to prevent a page refresh.
    }
  }
}
</script>
