<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="wizardDossierDetailsStep5Form"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <h2 class="tw-mt-0">Recht van voorkoop</h2>
    <CustomRadioGroup
      v-model="values.preemption"
      :options="YES_NO_NOT_ENTERED_IN_REQUEST_DOSSIER_DETAILS_WIZARD_OPTIONS"
      :mobile-row-layout="true"
      name="preemption"
      label="Wettelijk voorkooprecht van toepassing"
    />
    <FormulateInput
      type="translatable"
      name="preemption_description"
      label="Voorkooprecht - extra info"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3 tw-w-full']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :outer-class="['tw-flex tw-items-center']"
      :element-class="['tw-col-span-2']"
    />

    <hr class="tw-border-1 tw-border-gray-cc tw-my-4" />
    <h2>Overstromingsgevaar</h2>
    <PortfolioFloodingScores
      ref="floodingScores"
      :g-scores="values.g_scores"
      :p-scores="values.p_scores"
      class="tw-my-4"
    />
    <CustomRadioGroup
      v-model="values.flooding_plain"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="flooding_plain"
      label="Afgebakend overstromingsgebied"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.flooding_bank"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="flooding_bank"
      label="Afgebakende oeverzone"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.flooding_risk"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="flooding_risk"
      label="Risicozone voor overstromingen"
      class="tw-mb-4"
    />
    <FormulateInput
      type="translatable"
      name="flooding_info"
      label="Watertoets - extra info"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3 tw-w-full']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :outer-class="['tw-flex tw-items-center']"
      :element-class="['tw-col-span-2']"
    />

    <hr class="tw-border-1 tw-border-gray-cc tw-my-4" />
    <h2>Extra info</h2>
    <FormulateInput
      type="number"
      name="building_line"
      label="Rooilijn"
      min="0"
      step=".01"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :outer-class="['!tw-my-2']"
      :input-class="['!tw-mt-0 hide-number-input-arrows']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    />
    <CustomRadioGroup
      v-model="values.easement"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="easement"
      label="Erfdienstbaarheid"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.measures_of_expropriation"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="measures_of_expropriation"
      label="Maatregelen van onteigening"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.construction_violations"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="construction_violations"
      label="Bouwovertredingen begaan/vastgesteld"
      class="tw-mb-4"
    />

    <div class="tw-grid tw-grid-cols-2 md:tw-flex tw-justify-end tw-gap-x-2">
      <FormulateErrors />
      <FormulateInput
        type="button"
        :input-class="['tw-w-full tw-bg-gray-500']"
        :disabled="saving"
        @click="goBack"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
      <FormulateInput
        type="submit"
        title="Volgende stap"
        :input-class="['tw-w-full']"
        :disabled="isLoading"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
          ]"
        />
        Volgende <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import {
  YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS,
  YES_NO_NOT_ENTERED_IN_REQUEST_DOSSIER_DETAILS_WIZARD_OPTIONS,
  YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS
} from '@/forms/selectOptions'
import PortfolioFloodingScores from '@/components/properties/PortfolioFloodingScores'
import CustomRadioGroup from '@/components/base/CustomRadioGroup'
import { updatePropertyFinancial, updatePropertyLegal, updatePropertyTechnical } from '@/services/properties'

export default {
  name: 'WizardDossierDetailsStep6',
  components: {
    CustomRadioGroup,
    PortfolioFloodingScores
  },
  constants: {
    YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS,
    YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS,
    YES_NO_NOT_ENTERED_IN_REQUEST_DOSSIER_DETAILS_WIZARD_OPTIONS
  },
  props: {
    initialValues: {
      type: Object
    },
    propertyId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      values: {},
      saving: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.values = { ...this.initialValues }
    },
    async goBack () {
      this.saving = true
      const response = await this.submit(this.values, false)
      this.saving = false
      return response
    },
    async submit (data, goForward = true) {
      try {
        const {
          easement,
          preemption,
          preemption_description,
          flooding_plain,
          flooding_bank,
          flooding_risk,
          flooding_info,
          g_scores,
          p_scores,
          building_line,
          construction_violations,
          measures_of_expropriation
        } = data

        const financialPayload = { easement }
        const legalPayload = {
          preemption,
          preemption_description,
          flooding_plain,
          flooding_bank,
          flooding_risk,
          flooding_info,
          g_scores,
          p_scores
        }
        const technicalPayload = {
          building_line,
          construction_violations,
          measures_of_expropriation
        }

        const financialPromise = updatePropertyFinancial(this.propertyId, financialPayload)
        const legalPromise = updatePropertyLegal(this.propertyId, legalPayload)
        const technicalPromise = updatePropertyTechnical(this.propertyId, technicalPayload)

        const promises = [financialPromise, legalPromise, technicalPromise]
        const response = await Promise.all(promises)

        if (goForward) this.$emit('finished')
        else this.$emit('goBack')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'wizardDossierDetailsStep6Form')
        throw error
      }
    }
  }
}
</script>
